import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { subscribe } from "../events";

import "./Header.css";

function Header() {
  const [userName, setUsername] = useState("");
  const [daysLeftToEndOfTheMonth, setDaysLeftToEndOfTheMonth] = useState("");

  const calculateDaysToEndOfTheMonth = () => {
    let now = new Date();
    let nextMonth = new Date(new Date().setMonth(now.getMonth() + 1));
    nextMonth.setDate(1);

    setDaysLeftToEndOfTheMonth(
      parseInt((nextMonth - now) / (60 * 60 * 24 * 1000))
    );
  };

  const logout = () => {
    sessionStorage.clear();
    readUserName();
    window.location.replace("/login");
  };

  const readUserName = () => {
    var userName = sessionStorage.getItem("userName");
    console.log("header", userName);
    if (userName) {
      setUsername(userName);
    }
  };

  useEffect(() => {
    subscribe("login", () => readUserName());
    subscribe("logout", () => readUserName());
    readUserName();
    calculateDaysToEndOfTheMonth();
  }, []);

  return (
    <div className="main-header">
      <div className="date-container">
        <p>
          Dziś jest {new Date().toLocaleDateString()}. Do końca miesiąca
          pozostało : {daysLeftToEndOfTheMonth} dni
        </p>
      </div>
      <div className="top-menu-container">
        {userName === "" && (
          <p>
            <Link to="/login">Logowanie</Link> /{" "}
            <Link to="/register">rejestracja</Link>
          </p>
        )}
        {userName !== "" && (
          <>
            <p>Zalogowany : {userName}</p>
            <p>
              <Button variant="secondary" size="sm" onClick={logout}>
                Wyloguj
              </Button>
            </p>
          </>
        )}
      </div>
      <hr width="100%"></hr>
    </div>
  );
}

export default Header;

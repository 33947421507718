import React, { useState, useEffect } from "react";
import { Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { publish } from "../../events";
import apiClient from "../../services/apiClient";

import "./Account.css";
import DeleteAccount from "./DeleteAccount";
import UpdatePassword from "./UpdatePassword";

function Account() {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [amountPerDay, setAmountPerDay] = useState("");
  const [daysToEndOfBudget, setDaysToEndOfBudget] = useState(0);

  const navigate = useNavigate();

  const passwordChangeHandler = () => {
    sessionStorage.clear();
    publish("logout");
    window.location.replace("/login");
  };

  useEffect(() => {
    var userId = sessionStorage.getItem("userId");
    if (userId == null) {
      navigate("/login");
    }

    apiClient
      .authenticatedGet("/user/" + userId)
      .then((result) => {
        console.log(result);
        if (result) {
          setEmail(result.email);
          setName(result.name);
          setRegistrationDate(new Date(result.registeredDate).toLocaleString());
          setMobile(result.mobile);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setMessage("Wystąpił błąd : " + error.message);
      });

    apiClient
      .authenticatedGet("/budget")
      .then((result) => {
        console.log(result);
        if (result) {
          setAmountPerDay(result.amountPerDay);
          setDaysToEndOfBudget(result.daysToEndOfBudget);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setMessage("Wystąpił błąd : " + error.message);
      });
  }, [navigate]); //No dependency to trigger in each page load

  return (
    <div className="account-container">
      <h2>Konto</h2>
      <div className="container">
        <div className="row">
          {!message && (
            <div>
              <h3>Witaj {name}!</h3>
              <div className="paragraph-group">
                <p>Imię i nazwisko : {name}</p>
                <p>Email : {email}</p>
                <p>Nr komórki : {mobile}</p>
              </div>

              <div className="buttons-container">
                <Stack gap={3} className="col-md-5 mx-auto">
                  <UpdatePassword reset={passwordChangeHandler} />
                  <DeleteAccount reset={passwordChangeHandler}></DeleteAccount>
                </Stack>
              </div>
              <div className="paragraph-group">
                <p>Kwota na dzień : {amountPerDay} zł</p>
                <p>Do końca zostało : {daysToEndOfBudget} dni</p>
                <p>Konto utworzone : {registrationDate}</p>
              </div>
            </div>
          )}
          {message && <p>{message}</p>}
        </div>
      </div>
    </div>
  );
}

export default Account;
